import * as React from "react";
import styles from "./Home.module.scss"
import neige from '../../assets/neige.png';
import back from '../../assets/backabuzif.jpg';
import Footer from '../../shared/footer/Footer';
import Header from '../../shared/header/Header';
import MainPoster from './main_poster/component';
import FormEvent from './form_event/component';

function Home() {
  return (
    <>
    <Header />
    {/* <section className={styles.main}>
      <img src={back} alt="background" className={styles.back} id="picture" />
    </section> */}
    <MainPoster />
    <Footer />
    </>
  );
}
export default Home;



