import React from 'react';
import styles from './component.module.scss';
import or from "../../../assets/coupe_or.png";
import argent from "../../../assets/coupe_argent.png";
import bounty from "../../../assets/bounty.png";
import cible from "../../../assets/cible.png";
import lunette from "../../../assets/lunette.png";
import sandwich from "../../../assets/sandwich.png";

export function ClassementComponent() { 
const rank = [
    {pseudo: "AsDePic",      ms:[29],kill:12,main: 0,side: 0, killer: 0, retrait: 0, s1: 466},
    {pseudo: "AngkorVan",    ms:[16],kill:5,main: 0,side: 0, killer: 0, retrait: 0, s1: 318},
    {pseudo: "AsGeoff",      ms:[51],kill:14,main: 0,side: 0, killer:0, retrait: 0, s1: 290},
    {pseudo: "Axelos",       ms:[25],kill:13,main: 0,side: 0, killer:0, retrait: 0, s1: 269},
    {pseudo: "Barbidouille", ms:[48],kill:12,main: 0,side: 0, killer:0, retrait: 0, s1: 373},
    {pseudo: "BlackJack",    ms:[22],kill:17,main: 0,side: 0, killer:0, retrait: 0, s1: 298},
    {pseudo: "Boubou",       ms:[0],kill:12,main: 0,side: 0, killer:0, retrait: 0, s1: 89},
    {pseudo: "Bedeve",       ms:[35],kill:13,main: 0,side: 0, killer:0, retrait: 0, s1: 311},
    {pseudo: "BB",           ms:[4],kill:14,main: 0,side: 0, killer:0, retrait: 0, s1: 302},
    {pseudo: "Chouchen",     ms:[61],kill:8,main: 0,side: 0, killer:0, retrait: 0, s1: 253},
    {pseudo: "Cracott",      ms:[5],kill:1,main: 0,side: 0, killer:0, retrait: 0, s1: 210},
    {pseudo: "Davidou",      ms:[54],kill:4,main: 0,side: 0, killer:0, retrait: 0, s1: 406},
    {pseudo: "Drake",        ms:[42],kill:43,main: 0,side: 0, killer:1, retrait: 0, s1: 379},
    {pseudo: "Greg",         ms:[3],kill:3,main: 0,side: 0, killer:0, retrait: 0, s1: 178},
    {pseudo: "Kaelmy",       ms:[53],kill:5,main: 0,side: 0, killer:0, retrait: 0, s1: 151},
    {pseudo: "Ke20",         ms:[46],kill:14,main: 0,side: 0, killer:0, retrait: 0, s1: 326},
    {pseudo: "Lauroc",       ms:[39],kill:19,main: 0,side: 0, killer:0, retrait: 0, s1: 449},
    {pseudo: "Limace",       ms:[9],kill:19,main: 0,side: 0, killer:0, retrait: 0, s1: 275},
    {pseudo: "LSD",          ms:[20],kill:17,main: 0,side: 0, killer:0, retrait: 0, s1: 211},
    {pseudo: "Luckyluck",    ms:[45],kill:9,main: 0,side: 0, killer:0, retrait: 0, s1: 243},
    {pseudo: "Lycan",        ms:[18],kill:8,main: 0,side: 0, killer:0, retrait: 0, s1: 225},
    {pseudo: "Memed",        ms:[9],kill:6,main: 0,side: 0, killer:0, retrait: 0, s1: 338},
    {pseudo: "Mimi",         ms:[65],kill:11,main: 0,side: 0, killer:0, retrait: 0, s1: 408},
    {pseudo: "Milik",        ms:[79],kill:14,main: 1,side: 0, killer:0, retrait: 0, s1: 403},
    {pseudo: "Nanar",        ms:[4],kill:3,main: 0,side: 0, killer:0, retrait: 0, s1: 74},
    {pseudo: "Nuts",         ms:[48],kill:13,main: 0,side: 0, killer: 0, retrait: 0, s1: 315},
    {pseudo: "Pan28100",     ms:[0],kill:10,main: 0,side: 0, killer:0, retrait: 0, s1: 331},
    {pseudo: "Paincakke",    ms:[4],kill:0,main: 0,side: 0, killer:0, retrait: 0, s1: 85},
    {pseudo: "Pat",          ms:[47],kill:12,main: 0,side: 1, killer:0, retrait: 0, s1: 345},
    {pseudo: "PtitLoup",     ms:[36],kill:20,main: 0,side: 0, killer:0, retrait: 0, s1: 320},
    {pseudo: "Sharky",       ms:[41],kill:1,main: 0,side: 0, killer:0, retrait: 0, s1: 0},
    {pseudo: "Storm",        ms:[53],kill:8,main: 0,side: 0, killer:0, retrait: 0, s1: 378},
    {pseudo: "Toto",         ms:[76],kill:36,main: 0,side: 0, killer:0, retrait: 0, s1: 449},
    {pseudo: "Urbella",      ms:[52],kill:30,main: 0,side: 0, killer:0, retrait: 0, s1: 455},
    {pseudo: "Vulcain",      ms:[39],kill:6,main: 0,side: 0, killer:0, retrait: 0, s1: 277},
    ]

    rank.sort((a,b) => (b.ms.reduce((sum, p) => sum + p) - b.retrait) - (a.ms.reduce((sum, p) => sum + p) - a.retrait));
    
    let thRanking: any[] = [];
    for (let i = 0; i < 1; i++) {
        thRanking.push(<th className={styles.thRanking}>M {i+1}</th>);
    }

    return (
        <>
        <h1 className={styles.title1}>Classement</h1>
        
        <div className={styles.legendSandwich}>
          <h3>Sandwich <img src={sandwich} alt="sandwich" width={20}/></h3>
          <p>Main: <span>Asgeoff</span></p>
          <p>Side: <span>Lauroc</span></p>
        </div>
        <div className={styles.legendSandwich}>
          <h3>Bounty <img src={bounty} alt="bounty" width={20}/></h3>
          <p>Main: <span>Milik</span></p>
          <p>Side: <span>Pat</span></p>
        </div>
        <div className={styles.containerRanking}>
          <div className={styles.colFixed}>
            <table className={styles.tableRanking}>
              <thead className={styles.theadRanking}>
                <tr className={styles.trtheadRanking}>
                  <th className={styles.thRanking}>#</th>
                  <th className={styles.thRanking}>Pseudo</th>
                  <th className={styles.thRanking}>Victoire</th>
                  <th className={styles.thRanking}>Total S2</th>
                  <th className={styles.thRanking}>Total S1</th>
                  <th className={styles.thRanking}>Kill</th>
                </tr>
              </thead>
              <tbody className={styles.tbodyRanking}>
                {rank.map((r) => (
                  <tr className={styles.trtbodyRanking}>
                    <td className={styles.tdRanking}>{rank.indexOf(r) + 1}</td>
                    <td className={styles.tdRanking + styles.left}>{r.pseudo}</td>
                    <td className={styles.tdRanking}>{r.killer ? <img src={cible}/> :''}{winner(r.main,or,r.side,argent)}</td>
                    <td className={styles.tdRanking}>{r.ms.reduce((sum, p) => sum + p)}</td>
                    <td className={styles.tdRanking}>{r.s1}</td>
                    <td className={styles.tdRanking}>{r.kill}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.colOverflow}>
            <table className={styles.tableRanking}>
            <thead className={styles.theadRanking}>
                <tr className={styles.trtheadRanking}>
                    {thRanking}
                </tr>
            </thead>
              <tbody className={styles.tbodyRanking}>
                {rank.map((r) => (
                  console.log(r.ms),
                  <tr className={styles.trtbodyRanking}>
                    {r.ms && r.ms.map((m) => (
                        <td className={styles.tdRanking}>{m}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>   
        <div className={styles.legend}>
          <p><img src={or} alt='Main' title='Main' width={15} /> Winner Main</p>
          <p><img src={argent} alt='Side' title='Side' width={15} /> Winner Side</p>
          <p><img src={cible} alt='Killer' title='Killer' width={20} /> Best Killer</p>
        </div>
        <div className={styles.legendPoint}>
          <p>Main 2 Points par rang gagné, Side 1pts</p>
          <p>1 points par kill, bounty 2pts</p>
          <p>Bonification Main: +20pts, +14pts, +10pts, +8pts et du 5ème au 8ème +6pts</p>
          <p>Bonification Side: +7pts, +4pts, +2pts, +1pts</p>  
        </div>
        </>
    );
}

function winner(main: number,or: any, side: number, argent: any): any {
    let coupe: any[]= [];
    for (let i = 0; i < main; i++) {
        coupe.push(<img src={or} alt='winner' title='Main +5000' width={15} />);
    }

    for (let i = 0; i < side; i++) {
      coupe.push(<img src={argent} alt='winner' title='Side +2500' width={15} />);
    }
    return coupe;
  }